.text-center {
    text-align: center;
}

.ttu {
    text-transform: uppercase;
}

.printer-ticket {
    padding: 2mm;
    background: #ffffe0;
    width: 100%;
    line-height: 1.3em;
}

.printer-ticket,
.printer-ticket * {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 14px;
}

.printer-ticket th:nth-child(2),
.printer-ticket td:nth-child(2) {
    width: 100%;
}

.printer-ticket th:nth-child(3),
.printer-ticket td:nth-child(3) {
    width: 100%;
    text-align: right;
}
.printer-ticket th {
    font-weight: inherit;
    padding: 2mm 0;
    text-align: center;
    border-bottom: 2px dashed #000000;
}
.printer-ticket tbody tr:last-child td {
    padding-bottom: 2mm;
}

.printer-ticket tfoot .sup td {
    padding: 2mm 0;
    font-weight: bold;
    border-top: 2px dashed #000000;
}

.printer-ticket tfoot .sup.p--0 td {
    padding-bottom: 0;
}

.printer-ticket .title {
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
    padding: 2mm 0;
}

.printer-ticket .top td {
    padding-top: 2mm;
}

.printer-ticket .last td {
    padding-bottom: 2mm;
}