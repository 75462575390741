@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes popUpAnimation {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(0.95);
  }

  75% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

* {
  margin: 0;
  padding: 0;
  transition-duration: .3s;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

div {
  white-space: pre-line;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000000000s 0s, color 600000000000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
  overflow: hidden;
}

.content-cards-wrapper {
  width: max-content;
  height: max-content;
}

@media(max-width: 700px) {
  .content-cards-wrapper {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.toast-notifications {
  z-index: 99999999999999;
}